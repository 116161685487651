import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readSnakeCase'
})
export class ReadSnakeCasePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return value
      .split('_')
      .map((el: string) => el.charAt(0).toUpperCase() + el.slice(1))
      .join(' ');
  }
}
