import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NameRoute } from '@models/common/basics.model';
import { fadeInOut } from '@services/common/animations';
import { UiConfigService } from '@frpservices/uiconfig.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut]
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { static: true, read: ViewContainerRef })
  header: any;
  links: Array<NameRoute>;
  pageKey: string = 'Header';


  constructor(protected renderer: Renderer2, public uiConfigService: UiConfigService) {


  }

  ngOnInit(): void {
    this.initLinks();
  }

  setLanguage(lang: string) {
    this.uiConfigService.setLanguage(lang);
  }

  initLinks(): void {
    this.links = [
      {
        name: 'Enagagement',
        route: '/engagement'
      },

      {
        name: 'Upload',
        route: '/upload'
      }
    ];
  }

  back(): void {
    this.uiConfigService.showFundList = true;
    this.uiConfigService.showFundTitle = false;
  }

}
