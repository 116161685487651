import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormsService } from '@services/forms/forms.service';

@Component({
  selector: 'input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent implements OnInit {
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() options: Array<any>;
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string = '...';
  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;
  @Input() appearance: 'standard' | 'legacy' | 'fill' | 'outline' = 'fill';
  @Input() size: 'lg' | 'md' | 'sm' = 'md';
  @Input() displayName: string[];

  filteredOptions: any[];
  _key = 'name';

  get key(): string {
    return this._key;
  }

  @Input() set key(key: string) {
    if (key) {
      this._key = key;
    }
  }

  constructor(public formService: FormsService) {}

  ngOnInit() {
    this.subscribeControl(this.options);
  }

  displayFn = (entity?: any): string => {
    return entity ? this.getDisplayName(entity) : '';
  };

  subscribeControl(res: any[]): void {
    this.control.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe((value: string) => {
      this.filter(res, value);
    });
  }

  focusAutocomplete(): void {
    this.filteredOptions = [];
  }

  filter(list: any, value: string) {
    if (typeof value === 'string') {
      this.filteredOptions = list.filter((item:any) => item[this.key].toLowerCase().includes(value.toLowerCase()));
    }
  }

  get invalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  get errors(): Array<string> {
    if (this.control?.errors != null)
      return Object.keys(this.control?.errors);

    return [];
  }

  getDisplayName(option: any): string {
    const names = this.displayName.map((el) => option[el]);

    return names.join(', ');
  }
}
