<div mat-dialog-title class="dialog-title">
  <h2> {{ 'imprint' | i18n: pageKey }}</h2>
</div>

<div class="imprint-main">
  <section>
    {{ 'law' | i18n: pageKey }}
  </section>
  <section>
    <p>
      <strong>
        {{ 'company' | i18n: pageKey }}
      </strong>
    </p>
    {{ 'business_type' | i18n: pageKey }}
  </section>
  <section>
    {{ 'street_housenumber' | i18n: pageKey }}<br>
    {{ 'zip_city' | i18n: pageKey }}
  </section>
  <section>
    <strong>E-Mail: </strong><a href="mailto: webkontakt_anfragen@de.pwc.com">webkontakt_anfragen@de.pwc.com</a><br>
    <strong>Telefonzentrale: </strong>+49 69 9585-0<br>
    <strong>Fax: </strong>+49 69 9585-1000
  </section>
  <section>
    {{ 'business_description' | i18n: pageKey }}
  </section>
  <section>
    <strong>
      {{ 'domicile' | i18n: pageKey }}
    </strong>
    {{ 'domicile_location' | i18n: pageKey }}
  </section>
  <section>
    <p><strong>{{ 'chairman_supervisory_board_title' | i18n: pageKey }}</strong></p>
    WP StB Dr. Norbert Vogelpoth
  </section>
  <section>
    <p><strong>{{ 'management_board_title' | i18n: pageKey }}</strong></p>
    WP StB Prof. Dr. Norbert Winkeljohann • WP StB Dr. Peter Bartels • WP StB CPA Markus Burghardt • Dr. Klaus-Peter Gushurst • WP StB Petra Justenhoven • WP StB Harald Kayser • StB Marius Möller • StB Petra Raspels • WP StB Martin Scholich
  </section>
  <section>
    <strong>{{ 'tax_id_title' | i18n: pageKey }}</strong>DE194821795
  </section>
  <section>
    <p><strong>{{ 'supervisory_authority_title' | i18n: pageKey }}</strong></p>
    {{ 'supervisory_authority' | i18n: pageKey }}
  </section>
  <section>
    <p><strong>{{ 'provisions_and_regulations_title' | i18n: pageKey }}</strong></p>
    <ul>
      <li>Wirtschaftsprüferordnung (WPO)</li>
      <li>Berufssatzung für Wirtschaftsprüfer/vereidigte Buchprüfer (BS WP/vBP)</li>
      <li>Satzung für Qualitätskontrolle</li>
    </ul>
    <p></p>
    {{ 'provisions_and_regulations_information' | i18n: pageKey }}<a href="http://www.wpk.de/" target="_blank">http://www.wpk.de/</a>
  </section>
  <section>
    <strong>{{ 'professional_insurance_title' | i18n: pageKey }}</strong>
    {{ 'professional_insurance' | i18n: pageKey }}
  </section>
  <section>
    <strong>{{ 'codes_of_conduct_title' | i18n: pageKey }}</strong>
    {{ 'codes_of_conduct' | i18n: pageKey }}
    <ul>
      <li>
        {{ 'codes_of_conduct_ref1' | i18n: pageKey }}<a href="http://www.pwc.de/de/ethikcode" target="_blank">http://www.pwc.de/de/ethikcode</a>)
      </li>
      <li>
        {{ 'codes_of_conduct_ref2' | i18n: pageKey }}<a href="http://www.globalcompact.de/" target="_blank">http://www.globalcompact.de/</a>)
      </li>
    </ul>
  </section>
  <section>
    <p><strong>{{ 'further_companies_title' | i18n: pageKey }}</strong></p>
    <ul>
      <li>BDK Corporate Finance Beratung GmbH</li>
      <li>DRT Wirtschaftsberatungs- und Treuhandgesellschaft mbH</li>
      <li>Fachverlag Moderne Wirtschaft GmbH</li>
      <li>Industrie-Beratung Europa GmbH</li>
      <li>INTES Akademie für Familienunternehmen GmbH</li>
      <li>kampus GmbH</li>
      <li>PwC Europe Advisory GmbH</li>
      <li>PwC IT Services Europe GmbH</li>
      <li>PricewaterhouseCoopers Corporate Finance Beratung GmbH</li>
      <li>PricewaterhouseCoopers PRTM Management Consultants GmbH</li>
      <li>PwC cundus AG</li>
      <li>PwC FS Tax GmbH Wirtschaftsprüfungsgesellschaft</li>
      <li>PwC Treuhand GmbH Wirtschaftsprüfungsgesellschaft</li>
      <li>WIBERA Wirtschaftsberatung AG Wirtschaftsprüfungsgesellschaft</li>
    </ul>
    <p></p>
  </section>
  <section>
    <p><strong>{{ 'extrajudicial_settlement_title' | i18n: pageKey }}</strong></p>
    {{ 'extrajudicial_settlement' | i18n: pageKey }}<a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a>
  </section>
  <section>
    {{ 'contact1' | i18n: pageKey }}
    <a href="mailto: webkontakt_anfragen@de.pwc.com">webkontakt_anfragen@de.pwc.com</a>
    {{ 'contact2' | i18n: pageKey }}
  </section>
</div>
