<div class="nav-aside">
  <h2 class="title title_bold">{{ title | translate }}</h2>
  <nav class="nav-aside__menu">
    <ng-container *ngIf="links">
      <a *ngFor="let link of links" [routerLink]="link.route" routerLinkActive="active" class="nav-aside__link">
        {{ link.name | translate }}
      </a>
    </ng-container>
    <ng-container *ngIf="linksBlocks">
      <div *ngFor="let item of linksBlocks" class="nav-aside__block">
        <a *ngFor="let link of item" [routerLink]="link.route" routerLinkActive="active" class="nav-aside__link">
          {{ link.name | translate }}
        </a>
      </div>
    </ng-container>
  </nav>
</div>
