import { Component, Input } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { FormsService } from '@services/forms/forms.service';
import { fadeInOut } from '@services/common/animations';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  animations: [fadeInOut]
})
export class TextareaComponent {
  @Input() name: string;
  @Input() rows: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;
  @Input() maxLength: number = 1500;
  @Input() appearance: 'standard' | 'legacy' | 'fill' | 'outline' = 'legacy';

  constructor(public formService: FormsService) {}

  get invalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  get errors(): Array<string> {
    var errors: ValidationErrors = {};

    if (this.control?.errors != null) errors = this.control?.errors;

    return Object.keys(errors);
  }
}
