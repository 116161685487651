import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppTranslationService } from './app-translation.service';
import { DBkeys } from './db-keys';
import { LocalStoreManager } from '@services/common/local-store-manager.service';
import { environment } from '@env/environment';
import { Utilities } from '@services/common/utilities';

interface UserConfiguration {
  language: string;
}
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private _config: { [index: string]: string } = {};

  constructor(
    private localStorage: LocalStoreManager,
    private translationService: AppTranslationService,
    private http: HttpClient) {

    this.loadLocalChanges();
  }

  get data(): { [index: string]: string } {
    return this._config;
  }

  getConfig(key:string): string {
    return this._config[key];
  }


  loadAppConfig(): Observable<{ [index: string]: string }> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.get(`/appsettings.json?cb=${new Date().getTime()}`, { headers }).pipe(
      map((data: {[index:string]:any}) => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {          
            this._config[key] = data[key]?.toString();
          }
        }
        return this.data;
      })
    );
  }

  // **** //

  public static readonly defaultHomeUrl: string = '/';
  public static readonly appVersion: string = '1.0.0';
  public static readonly apiVersion: string = 'v1';

  get apiUrl(): string {

    //return this.baseUrl + '/api/' + ConfigurationService.apiVersion;
    return 'api/' + ConfigurationService.apiVersion;
  }

  get apiIsAuthenticatedUrl() {
    return this.baseUrl + '/api/home/isAuthenticated';
  }

  get apiLoginUrl() {
    return this.baseUrl + '/api/' + ConfigurationService.apiVersion + '/account/login';
  }

  get apiLogoutUrl() {
    return this.baseUrl + '/api/' + ConfigurationService.apiVersion + '/account/logout';
  }

  get homeUrl() {
    return ConfigurationService.defaultHomeUrl;
  }

  // **** //
 
  public baseUrl = environment.baseUrl || Utilities.baseUrl();
  public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
  public loginUrl = environment.loginUrl;
  public fallbackBaseUrl = 'http://www.pwc.com';

  // **** //

  public static readonly clients_Path: string = '/Clients';
  public static readonly funds_Path: string = '/Funds';
  public static readonly documents_Path: string = '/Documents';
  public static readonly dataSets_Path: string = '/DataSets';
  public static readonly test: string = '';
  
  // **** //
  private _language: string = '';

  public static readonly defaultLanguage: string = 'en';

  set language(value: string) {
    this._language = value;
    this.saveToLocalStore(value, DBkeys.LANGUAGE);
    this.translationService.changeLanguage(value);
  }
  get language() {
    return this._language || ConfigurationService.defaultLanguage;
  }

   // **** //

  private onConfigurationImported: Subject<boolean> = new Subject<boolean>();
  configurationImported$ = this.onConfigurationImported.asObservable();

  private loadLocalChanges() {

    if (this.localStorage.exists(DBkeys.LANGUAGE)) {
      this._language = this.localStorage.getDataObject<string>(DBkeys.LANGUAGE) || '';
      this.translationService.changeLanguage(this._language);
    } else {
      this.resetLanguage();
    }
  }

  private saveToLocalStore(data: any, key: string) {
    setTimeout(() => this.localStorage.savePermanentData(data, key));
  }

  public import(jsonValue: string) {

    this.clearLocalChanges();

    if (jsonValue) {
      const importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

      if (importValue.language != null) {
        this.language = importValue.language;
      }

      this.onConfigurationImported.next();
    }
  }

  public export(changesOnly = true): string {

    const exportValue: UserConfiguration = {
      language: changesOnly ? this._language : this.language      
    };

    return JSON.stringify(exportValue);
  }

  public clearLocalChanges() {
    this._language = '';

    this.localStorage.deleteData(DBkeys.LANGUAGE);

    this.resetLanguage();
  }

  private resetLanguage() {
    const language = this.translationService.useBrowserLanguage();

    if (language) {
      this._language = language;
    } else {
      this._language = this.translationService.useDefaultLangage();
    }
  }

}
