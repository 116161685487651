import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secureId'
})
export class SecureIdPipe implements PipeTransform {
  transform(_id: number): any {
    return !isNaN(Number(_id)) ? encodeURI(_id.toString()) : null;
  }
}
