import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() label: string;
  @Input() clearBtn: boolean;
  @Output() search: EventEmitter<string> = new EventEmitter();
  form: UntypedFormGroup;

  constructor(protected fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute) {}

  get control(): UntypedFormControl {
    return this.form.get('search') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const query = params.search;
      this.form = this.fb.group({ search: params.search });
      if (query) {
        this.search.emit(query);
      }
    });
  }

  onClear() {
    this.onSubmit();
  }

  onSubmit(): void {
    const searchKey = this.form.value.search;
    const queryParams = {
      search: (searchKey || '').trim()
    };
    this.router
      .navigate([], {
        queryParams: searchKey ? queryParams : null
      })
      .then(() => {
        this.search.emit(searchKey);
      });
  }
}
