import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesKey'
})
export class IncludesKeyPipe implements PipeTransform {

  transform(object: any, key: string): boolean {
    return Object.keys(object).includes(key);
  }

}
