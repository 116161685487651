import { Component } from '@angular/core';
import { fadeInOut } from '../../services/common/animations';

@Component({
    selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
    animations: [fadeInOut]
})
export class LogoutComponent {
}
