import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { OrderedPair } from '@models/common/basics.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  readonly errorMessages: {[index:string]:string} = {
    required: 'error_message_required',
    email: 'error_message_email',
    phone: 'error_message_phone',
    pattern: 'error_message_invalid',
    minlength: 'error_message_tooShort',
    maxlength: 'error_message_tooLong',
    maxValue: `error_message_tooBig`,
    noPasswordMatch: `error_message_confirmPassword`,
    url: `error_message_notUrl`,
    workingUrl: `error_message_workingUrl`,
    invalidToken: `error_message_invalidToken`,
    alreadyExist: `error_message_exist`,
    toDateLess: `error_message_alreadyExist`,
    notString: `error_message_autocomplete`
  };

  constructor(protected fb: UntypedFormBuilder, protected translate: TranslateService) { }

  addControls(options: Array<OrderedPair>, selectedOptions: Array<string | number> = []): Array<UntypedFormControl> {
    return options.map((option: OrderedPair) => {
      return this.fb.control(!!selectedOptions.find((el: string | number) => el === option.value));
    });
  }

  getSelectedValues(options: Array<OrderedPair>, formArray: UntypedFormArray): Array<string | number> {
    const arr: Array<string | number> = [];

    options.forEach((el: OrderedPair, i: number) => {
      if (formArray.value[i]) {
        return arr.push(el.value);
      }
      return null;
    });

    return arr;
  }

  getSelectedObjects(options: Array<OrderedPair>, formArray: UntypedFormArray): Array<OrderedPair> {
    const arr: Array<OrderedPair> = [];

    options.forEach((el: OrderedPair, i: number) => {
      if (formArray.value[i]) {
        return arr.push({ key: el.key, value: el.value });
      }
      return null;
    });

    return arr;
  }

  getError(key: string): string {
    return this.errorMessages[key] ? this.translate.instant(`Error_messages.${this.errorMessages[key]}`) : null;
  }
}
