import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fadeInOut } from '@services/common/animations';
import { FormsService } from '@services/forms/forms.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [fadeInOut]
})
export class InputComponent {
  pageKey = `Error_messages`;
  @Output() clear: EventEmitter<void> = new EventEmitter();
  @Input() name: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;
  @Input() appearance: 'standard' | 'legacy' | 'fill' | 'outline' = 'fill';
  @Input() size: 'lg' | 'md' | 'sm' = 'md';
  @Input() clearBtn: boolean;

  constructor(public formService: FormsService) {}

  get invalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  get errors(): Array<string> {
    if (this.control?.errors != null)
      return Object.keys(this.control?.errors);

    return [];
  }

  clearInput() {
    this.control.setValue('');
    this.clear.emit();
  }
}
