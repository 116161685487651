import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UiConfigService } from '@frpservices/uiconfig.service';

@Pipe({
  name: 'i18n',
  pure: false
})
export class I18nPipe implements PipeTransform, OnDestroy {
  private markForTransform = true;
  private value: string;
  private changes$: Subscription;
  private translate: TranslateService;

  constructor(public uiConfigService: UiConfigService) {
    this.translate = uiConfigService.getTranslateService();
    this.translate.onLangChange.subscribe(() => {
      this.markForTransform = true;
    });
  }

  transform(key: string, pageName: string, params?: any): any {
    //console.log("###transform ###" + pageName + "/" + key);
    if (!this.markForTransform) {
      return this.value;
    }

    this.value = this.translate.instant(`${pageName}.${key}`, params);
    this.markForTransform = false;    
    return this.value;
  }

  ngOnDestroy() {
    if (this.changes$) {
      this.changes$.unsubscribe();
    }
  }
}
