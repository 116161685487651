import { Component, OnInit } from '@angular/core';
import { UiConfigService } from '@frpservices/uiconfig.service';
import { TranslateService } from '@ngx-translate/core';

// ref.: https://pwcplus.de/de/impressum/


@Component({
  selector: 'imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  translateService: TranslateService;
  language: string;
  pageKey: string = 'Imprint';

  constructor(
    public uiConfigService: UiConfigService
  ) {
    this.translateService = uiConfigService.getTranslateService();
  }
  
  ngOnInit() {
    this.language = this.uiConfigService.selectedLang;
  }

}
