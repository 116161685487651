<div class="checkboxes-group">
  <div
    *ngFor="let control of formArray.controls; let i = index"
    fxLayout
    fxLayoutAlign="start baseline"
    [class.checkboxes-group__row_other]="isManualEnter(i)"
    class="checkboxes-group__row"
  >
    <!-- <mat-checkbox [formControl]="control" color="primary">
      <span *ngIf="!isManualEnter(i); else otherLabel">{{ options[i].desc }}</span>
      <ng-template #otherLabel>Other:</ng-template>
    </mat-checkbox> -->
    <mat-form-field *ngIf="isManualEnter(i)" color="primary" floatLabel="never" class="checkboxes-group__other-input">
      <input
        aria-label="other variant"
        id="other"
        matInput
        [required]="getControl(control).value"
        [disabled]="!getControl(control).value"
        [(ngModel)]="otherValue"
      />
    </mat-form-field>
  </div>
</div>
