import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NameRoute } from '@models/common/basics.model';
import { fadeInOut } from '@services/common/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [fadeInOut]
})
export class FooterComponent implements OnInit {
  @ViewChild('footer', { static: true, read: ViewContainerRef })
  footer: any;
  pageKey: string = 'Footer';
  links: Array<NameRoute>;
  currentYear = new Date().getFullYear(); // Das aktuelle Jahr abrufen

  constructor(protected renderer: Renderer2, public dialog: MatDialog) { }

  ngOnInit(): void {

  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
