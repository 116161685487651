<div class="control" [class.error]="invalid">
  <mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <div class="control__textarea">
      <textarea
        [formControl]="control"
        [readonly]="readonly"
        [rows]="rows"
        placeholder="{{ placeholder }}"
        name="{{ name }}"
        id="{{ name }}"
        matInput
        [maxlength]="maxLength.toString()"
      ></textarea>
    </div>
    <mat-error class="control__error-msg" *ngIf="invalid">
      <p *ngFor="let errorKey of errors">
        <span *ngIf="formService.errorMessages[errorKey]" [@fadeInOut]>{{ formService.errorMessages[errorKey] }}</span>
      </p>
    </mat-error>
  </mat-form-field>
</div>
