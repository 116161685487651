import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayMode } from '@models/common/basics.model';

@Component({
  selector: 'app-upload-preview-photo',
  templateUrl: './upload-preview-photo.component.html',
  styleUrls: ['./upload-preview-photo.component.scss']
})
export class UploadPreviewPhotoComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() mode: DisplayMode;
  @Input() imageUrl:string;
  @Output() uploaded: EventEmitter<File> = new EventEmitter<File>();

  ngOnInit() {
    if (!this.disabled) {
      this.disabled = this.mode === DisplayMode.VIEW || this.mode === DisplayMode.FEEDBACK;
    }
  }

  onSelectFile(event: any) {
    if (event?.target?.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.uploaded.emit(file);
    }
  }

  remove(e:any): void {
    e.preventDefault();

    this.uploaded.emit();
  }
}
