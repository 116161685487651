<div [ngClass]="{ 'flex flex_center': isRow }">
  <div class="file-upload" [class.file-upload_row]="isRow" (click)="fileInput.click()">
    <div [ngClass]="{ 'mat-flat-button mat-primary': isRow, lg: !isSmall }">
      <p>{{ label }}</p>
      <i class="material-icons">{{ iconName || 'attachment' }}</i>
    </div>
    <input hidden type="file" #fileInput (change)="onChange()" />
    <span class="file-upload__filename">{{ fileName }}</span>
  </div>
  <a *ngIf="fileLink && !fileName" [href]="fileLink" target="_blank" class="file-upload__download">{{
    uploadedFileName
  }}</a>
</div>
