<div class="custom-autocomplete">
  <mat-form-field [appearance]="appearance" [ngClass]="size">
    <input
      matInput
      type="text"
      [placeholder]="placeholder"
      [formControl]="control"
      [matAutocomplete]="auto"
      (focus)="focusAutocomplete()"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ displayName ? getDisplayName(option) : option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="invalid" class="control__error-msg">
      <p *ngFor="let errorKey of errors">
        <span *ngIf="formService.getError(errorKey)">{{ formService.getError(errorKey) }}</span>
      </p>
    </mat-error>
  </mat-form-field>
</div>
