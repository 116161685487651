import { Utilities } from "@services/common/utilities";
import { Local } from "protractor/built/driverProviders";
import { ClientService } from "../services/client.service";

/**
 * Model that matches the backend's
 * */
export class Client {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  validFrom: Date;
  validUntill: Date;
  active: boolean;
  createdBy: string;
  modifiedBy: string;
  id: string;
  partitionKey: string;
  context: string;
  name: string;
  description: string;
  protectedClient: boolean;
  countries: Array<string>;
  addresses: Array<any>;
  legalMasterData: Array<any>;
  generalConfigurations: ClientConfiguration;
  countryConfigurations: { [key: string]: ClientConfiguration; } = {};
  Funds: Array<Fund>;


  public getLogoBase64(country: string | null): string {
    if (country == null || Utilities.stringIsNullOrWhiteSpace(country as string)) return this.generalConfigurations.uiConfigurations.logoBase64;

    if (this.countryConfigurations[country] && this.countryConfigurations[country] != null) return this.countryConfigurations[country].uiConfigurations.logoBase64;

    return this.generalConfigurations.uiConfigurations.logoBase64;
  }
   

  public DeepCopyFrom(client: any) {

    this.addresses = [];
    this.legalMasterData = [];
    this.countryConfigurations = {};
    this.generalConfigurations = new ClientConfiguration();

    Object.assign(this, client);
    if (client.addresses && client.addresses != null) Object.assign(this.addresses, client.addresses);
    if (client.countryConfigurations && client.countryConfigurations != null) Object.assign(this.countryConfigurations, client.countryConfigurations);
    if (client.Funds && client.Funds != null) Object.assign(this.Funds, client.Funds);
    if (client.generalConfigurations && client.generalConfigurations != null) Object.assign(this.generalConfigurations, client.generalConfigurations);
    if (client.legalMasterData && client.legalMasterData != null) Object.assign(this.legalMasterData, client.legalMasterData);
  }
}

/**
 * Model that matches the backend's
 * */
export class ClientConfiguration {
  dataSetSubjectType: string;
  recordMetaDataKeys: { [key: string]: string; } = {};
  dataSetMetaDataMappigs: { [key: string]: string; } = {};
  queries: { [key: string]: string; } = {};
  uiConfigurations: UiConfiguration;
}

/**
 * Model that matches the backend's
 * */
export class UiConfiguration {
  logoBase64: string;
  viewConfigurations: any;
}

/**
 * Fund model that matches the backend's model
 * For example, it has seriesIdentifiers as an Array<string>
 * */
export class Fund {
  // lower case

  clientid: string;
  subfundname: string;
  umbrellaname: string;
  shareclassname: string;
  shareclasscurrency: string;
  seriesidentifier: string;
  fundqualification: string;
  distributionpolicy: string;
  fiscalyearend: Date;
  fundtype: string;
  subjectid: string;
  currentlyinclosing: boolean;
  mergerdate: string;

  // camelCase
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  validFrom: Date;
  validUntill: Date;
  active: boolean;
  createdBy: string;
  modifiedBy: string;
  id: string;
  partitionKey: string;
  context: string;
  name: string;
  description: string;
  clientId: string;
  isin: string;
  subFundName: string;
  umbrellaName: string;
  shareClassName: string;
  shareClassCurrency: string;
  fundQualification: string;
  distributionPolicy: string;
  status: string;
  //seriesIdentifiers: Array<string>;
  currencies: Array<string>;
  countries: Array<string>;
  fiscalYearEnd: string;
  fundType: string;
  website: string;
  subjectId: string;
  currentlyInClosing: string;
  closed: string;
  mergerDate: string;
  additionalProperties: { [key: string]: any; } = {};

  public DeepCopyFrom(fund: any) {

    //this.seriesIdentifiers = [];
    this.currencies = [];
    this.countries = [];
    this.additionalProperties = {};

    Object.assign(this, fund);
    //if (fund.seriesIdentifiers && fund.seriesIdentifiers != null) Object.assign(this.seriesIdentifiers, fund.seriesIdentifiers);
    if (fund.currencies && fund.currencies != null) Object.assign(this.currencies, fund.currencies);
    if (fund.countries && fund.countries != null) Object.assign(this.countries, fund.countries);
    if (fund.additonalProperties && fund.additonalProperties != null) Object.assign(this.additionalProperties, fund.additonalProperties);

  }

  public GetSubjectId() {
    if (!Utilities.stringIsNullOrWhiteSpace(this.subjectId)) return this.subjectId;

    if (Utilities.stringIsNullOrWhiteSpace(this.seriesidentifier))
      return this.isin + '|';
    else
      return this.isin + '|' + this.seriesidentifier;
  }
}

export class Dataset {
  records: Array<Record>;
}

/**
 * Model that matches the backend's
 * */
export interface Record {
  metadata: {
    type: string;
    year: string;
    preliminary: string;
  }
  fields: any;
}

/**
 * Model that we use to send information to the view
 * */
export class FundOverviewConfig {
  client: Client;
  investorType: string;
  selectedCountry: string | null = null;
  selectedSection: string | null = null;
  search: string | null = null;
}

/**
 * Model that we conditionally use to filter by country and/or investor type in the disclaimer page when the required settings are set in the client config
 * */
export class FilterConfig {
  filterCountries: Array<string> = [];
  filterCountry: string | null = null;
  filterSections: { [key: string]: Array<string> } = {};
  filterSection: string | null = null;
}



/**
 * Model that we use to send information to the view
 * */
export enum FundOverviewColumnTypes {
  ShowValue = 'ShowValue',
  Link = 'Link',
  Date = 'Date',
  Float = 'Float',
  MetaData = 'MetaData',
  DetailButton = 'Button',
  CountryDropdown = 'Dropdown'
}

/**
 * Model that we use to send information to the view
 * */
export class FundDetailsConfig {
  client: Client;
  investorType: string;
  fund: Fund;
  selectedCountry: string;
  showBackToFunds: boolean = true;
}

/**
 * Model that we use to send information to the view
 * */
export class Section {
  key: string;
  showSubsectionFilter: boolean;
  subtypeField: string;
  subsectionFilters: { [key: string]: FundOverviewColumnTypes } = {};
  totalValues: { [key: string]: string } = {};
  totalSums: Array<string> = [];
}

/**
 * Model that we use to send information to the view
 * */
export enum Part {
  Record = 'Record',
  Download = 'Download'
}

/**
 * Model that simplyfies working with date/time information
 * */
export class Timestamp {
  year: number;
  month: number;
  day: number;
  hours: number;
  minutes: number;
}


/**
 * Model that we use to save information needed for creating a certificate.
 * */
export class RecordsSettings {
  records: Array<Record> = [];
  years: Array<string> = [];
}

/**
 * Model that we use to save information needed for creating a certificate.
 * */
export class CertificateSettings {
  isDownloading: boolean;
}

/**
 * Model that we use to save information needed for filtering a graph.
 * */
export class FilterSettings {
  years: Array<string> = [];
  year: string | null = null;
  subsectionFilters: Array<string> = [];
  subsectionFilter: string | null = null;
}

export enum PageKey {
  disclaimer = "disclaimer",
  funds = "funds",
  fundDetails = "fundDetails",
  certificate = "certificate"
}

export interface ViewConfiguration {
  localeTexts: any,
  viewOptions: any
}
