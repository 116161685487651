import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secureUuid'
})
export class SecureUuidPipe implements PipeTransform {
  transform(uuid: string): any {
    const regexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexp.test(uuid) ? uuid.toString() : null;
  }
}
