<mat-form-field class="datepicker" (click)="datePicker.open()" [appearance]="appearance">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    readonly
    [matDatepicker]="datePicker"
    [formControl]="control"
    [matDatepickerFilter]="dateFilter"
    [placeholder]="placeholder"
    [name]="name"
    [min]="min"
    [max]="max"
  />
  <mat-datepicker-toggle [for]="datePicker" matSuffix [disabled]="disabled"></mat-datepicker-toggle>
  <mat-datepicker
    #datePicker
    [disabled]="disabled"
    color="primary"
    [calendarHeaderComponent]="customHeader || null"
    (monthSelected)="monthSelected.emit($event)"
  ></mat-datepicker>
</mat-form-field>
