import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nav-aside',
  templateUrl: './nav-aside.component.html',
  styleUrls: ['./nav-aside.component.scss']
})
export class NavAsideComponent implements OnInit {
  @Input() title: string;
  @Input() links: Array<{ name: string; route: string }>;
  @Input() linksBlocks: Array<Array<{ name: string; route: string }>>;

  constructor() {}

  ngOnInit() {}
}
