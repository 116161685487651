import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => {
    return new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        const langToSet = ['en'];
        const langDefault = langToSet[0];

        translate.setDefaultLang(langDefault);
        translate.use(langDefault).subscribe(
          () => {},
          () => {},
          () => resolve(null)
        );
      });
    });
  };
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}
