<div class="container h100 dashboard">
    <header class="pageHeader title title_border left-right-gap">
        <h3><i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> {{ '404' | i18n: pageKey  }}</h3>
    </header>
    <mat-card class="introcontent box left-right-gap">
        <div class="card-content">
            <div [@fadeInOut] class="d-flex h-75">
                <div class="m-auto">
                    <div class="icon-container">{{'404' | i18n: pageKey }}</div>
                    <div class="text-muted error-description gap">{{'pageNotFound' | i18n: pageKey }}</div>
                </div>
            </div>
        </div>
    </mat-card>
</div>