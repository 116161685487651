<div id="header-spacer"></div>
<header id="main-header" class="header-main" #header>
  <div class="header-top" *ngIf="uiConfigService.visibleMainHeader">
    <div class="header-left">
      <div class="header-top__logo">
        <a class="logo">
          <img src="/assets/images/fund_reporting_portal.svg" class="logo__icon">
        </a>
      </div>
    </div>

    <div class="header-right">
      <div class="dropdown">
        <mat-form-field appearance="fill">
          <mat-select [(value)]="uiConfigService.selectedLang">
            <mat-option *ngFor="let lang of uiConfigService.langList" (click)="setLanguage(lang);" [value]="lang">
              {{ lang | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <img *ngIf="uiConfigService.clientLogoSafePath" [src]="uiConfigService.clientLogoSafePath" class="logo2__icon">

    </div>
  </div>

  <div class="header-nav" *ngIf="uiConfigService.visibleNavHeader">

    <div class="header-top__logo">
      <span class="header-top__name">{{ '_title' | translate }}</span>
    </div>

    <nav [@fadeInOut]>
      <a *ngFor="let link of links"
         class="header-nav__item"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: link.route === '/' }"
         [routerLink]="link.route">
        <span>{{ link.name | translate }}</span>
      </a>
    </nav>
    <div class="dropdown">
      <button class="dropbtn languageselector">
      </button>
      <div class="dropdown-content-tiny">
        <a *ngFor="let lang of uiConfigService.langList" (click)="setLanguage(lang)">
          <i>{{ lang | uppercase }}</i>
        </a>
      </div>
    </div>
  </div>

</header>
