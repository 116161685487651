<div class="control" [class.error]="invalid">
  <mat-form-field [appearance]="appearance" [ngClass]="size">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <div class="control__input">
      <input
        [formControl]="control"
        [autocomplete]="name"
        [type]="type || 'text'"
        [readonly]="readonly"
        [attr.min]="type === 'number' ? 0 : null"
        placeholder="{{ placeholder }}"
        name="{{ name }}"
        autocomplete="{{ name }}"
        id="{{ name }}"
        matInput
      />
    </div>
    <button
      *ngIf="clearBtn && control.value"
      (click)="clearInput()"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Clear"
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="invalid" class="control__error-msg">
      <p *ngFor="let errorKey of errors">
        <span *ngIf="formService.errorMessages[errorKey]" [@fadeInOut]>
          {{ formService.errorMessages[errorKey] | i18n: pageKey }}
        </span>
      </p>
    </mat-error>
  </mat-form-field>
</div>
