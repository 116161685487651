<mat-card *ngIf="show" [@fadeInOut] class="notification" [ngClass]="typeClass">
  <div class="notification__content">
    <div class="notification__icon">
      <mat-icon>{{ icons[type] }}</mat-icon>
    </div>
    <ng-content></ng-content>
  </div>
  <button *ngIf="allowClose" (click)="onClose()" type="button" class="notification__close">
    <mat-icon>cancel</mat-icon>
  </button>
</mat-card>
