import { Component, Input, OnInit } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() control: UntypedFormControl;

  hiddenControl: UntypedFormControl;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#404041'
    },
    dial: {
      dialBackgroundColor: '#ccc',
      dialEditableActiveColor: '#404041',
      dialActiveColor: '#404041',
      dialEditableBackgroundColor: '#404041',
      dialInactiveColor: 'rgba(64, 64, 65, 0.6)'
    },
    clockFace: {
      clockFaceBackgroundColor: '#f2f2f2',
      clockHandColor: '#e0301e',
      clockFaceTimeInactiveColor: '#404041'
    }
  };

  constructor() {}

  ngOnInit() {
    this.hiddenControl = new UntypedFormControl(this.control.value.substring(0, 5));

    this.hiddenControl.valueChanges.subscribe((time) => this.control.setValue(`${time}:00`));
  }
}
