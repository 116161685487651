import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupByPipe } from '@app/pipes/group-by.pipe';
import { FullNamePipe } from '@app/pipes/full-name.pipe';
import { PwdStrengthPipe } from '@app/pipes/pwd-strength.pipe';
import { YesNoPipe } from '@app/pipes/yes-no.pipe';
import { ReadSnakeCasePipe } from '@app/pipes/read-snake-case.pipe';
import { ToClassPipe } from '@app/pipes/to-class.pipe';
import { DurationPipe } from '@app/pipes/duration.pipe';
import { NoCommaPipe } from '@app/pipes/no-comma.pipe';
import { SecureIdPipe } from '@app/pipes/secure-id.pipe';
import { SecureUuidPipe } from '@app/pipes/secure-uuid.pipe';
import { I18nPipe } from './i18n.pipe';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateLanguageLoader } from '../services/configurations/app-translation.service';
import { KeysPipe } from './keys.pipe';
import { IncludesKeyPipe } from './includes-key.pipe';
import { TransformDateFormatPipe } from './transform-date-format.pipe';
import { TransformFloatFormatPipe } from './transform-float-format.pipe';

const pipes = [
  GroupByPipe,
  FullNamePipe,
  PwdStrengthPipe,
  YesNoPipe,
  ReadSnakeCasePipe,
  ToClassPipe,
  DurationPipe,
  NoCommaPipe,
  SecureIdPipe,
  SecureUuidPipe,
  I18nPipe,
  KeysPipe,
  IncludesKeyPipe,
  TransformDateFormatPipe,
  TransformFloatFormatPipe
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    })
  ],
  exports: [...pipes,
    TranslateModule
  ],
  providers: [...pipes,
    TranslatePipe
  ]
})
export class PipesModule {}
