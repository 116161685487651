import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() control: UntypedFormControl;
  @Input() appearance: 'standard' | 'legacy' | 'fill' | 'outline' = 'fill';
  @Input() size: 'lg' | 'md' | 'sm' = 'md';
  @Input() isFlagPlaceholder: boolean;
  selected: string;

  constructor(protected fb: UntypedFormBuilder) {}

  ngOnInit() {
    //console.log(this.isFlagPlaceholder);
    // isFlagPlaceholder is set to true if a client configuration has only one country
    // -> in that case all fund details buttons should be active with the preselected country as default
    if (this.isFlagPlaceholder && this.isFlagPlaceholder == true) {
      this.selected = this.options[0]; // set flag image as placeholder
      this.control.setValue(this.options[0]); // activate fund details button of the related form
    }
  }
}
