import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImprintComponent } from '@frp/modules/imprint/imprint.component';
import { PrivacyComponent } from '@frp/modules/privacy/privacy.component';
import { OpensourceComponent } from '@frp/modules/opensource/opensource.component';
import { AboutComponent, NotFoundComponent } from './components';
import { AuthGuard } from './services/infrastructure/authentication/auth-guard.service';
import { AuthService } from './services/infrastructure/authentication/auth.service';


export const routes: Routes = [
  {
    path: '',
    //canActivate: [AppResolverService],
    children: [
      {
        path: 'client',
        loadChildren: () => import('@frp/modules/funds/funds.module').then((m) => m.FundsModule)
      },
      {
        path: 'fund-list',
        loadChildren: () => import('@frp/modules/funds/funds.module').then((m) => m.FundsModule)
      },
      {
        path: 'fund-details',
        loadChildren: () => import('@frp/modules/funds/funds.module').then((m) => m.FundsModule)
      },
      {
        path: 'about',
        pathMatch: 'full',
        component: AboutComponent,
      },
      {
        path: 'legal notice',
        pathMatch: 'full',
        component: ImprintComponent,
      },
      {
        path: 'privacy',
        pathMatch: 'full',
        component: PrivacyComponent,
      },
      {
        path: 'oss',
        pathMatch: 'full',
        component: OpensourceComponent,
      },
      {
        path: '401',
        pathMatch: 'full',
        component: NotFoundComponent
      },
      {
        path: '403',
        pathMatch: 'full',
        component: NotFoundComponent
      },
      {
        path: '404',
        pathMatch: 'full',
        component: NotFoundComponent
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard
    ]
})
export class AppRoutingModule { }
