import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaterialModule } from './material.module';
import { PipesModule } from '@pipes/pipes.module';


// Components
import * as components from '@components/index';

const sharedComponents = [
  components.AboutComponent,
  components.CheckboxGroupComponent,
  components.ConfirmationDialogComponent,
  components.DatepickerComponent,
  components.EmptyViewComponent,
  components.FileUploadComponent,
  components.InputAutocompleteComponent,
  components.InputComponent,
  components.MatButtonComponent,
  components.NavAsideComponent,
  components.PageTitleComponent,
  components.SearchComponent,
  components.SelectComponent,
  components.StaticNotificationComponent,
  components.TextareaComponent,
  components.TimepickerComponent,
  components.UploadButtonComponent,
  components.UploadPreviewPhotoComponent,
  components.NotFoundComponent,
  components.GlobalSpinnerComponent
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule, NgxMaterialTimepickerModule, MatFormFieldModule,
    AngularSvgIconModule.forRoot(),
    RouterModule,
    PipesModule
  ],
  exports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule, NgxMaterialTimepickerModule, MatFormFieldModule,
    AngularSvgIconModule,
    RouterModule,
    PipesModule,
    ...sharedComponents
  ],
  providers: [ 
    
  ],
})
export class SharedModule {}
