import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformFloatFormat'
})
export class TransformFloatFormatPipe implements PipeTransform {

  transform(floatString: string, language: string): string {

    if (!floatString) {

      return "";

    }

    if (language == "en") {

      return floatString;

    }

    if (language == "de") {

      return floatString.replace(".", ",");

    }

    return floatString;

  }

}
