<div class="timepicker">
  <b *ngIf="label" class="timepicker__label">{{ label }}</b>
  <div class="timepicker__input">
    <ngx-material-timepicker-toggle [for]="fullTime"></ngx-material-timepicker-toggle>
    <mat-form-field appearance="fill">
      <input [formControl]="control" [placeholder]="placeholder" aria-label="time" readonly matInput />
    </mat-form-field>
  </div>
  <input
    [formControl]="hiddenControl"
    [ngxTimepicker]="fullTime"
    [format]="24"
    aria-label="timepicker"
    readonly
    hidden
  />
  <ngx-material-timepicker #fullTime [theme]="darkTheme"></ngx-material-timepicker>
</div>
