import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {ComponentType} from '@angular/cdk/overlay';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() placeholder: string = '';
  @Input() min: Date;
  @Input() max: Date;
  @Input() appearance: 'standard' | 'legacy' | 'fill' | 'outline' = 'fill';
  @Input() dateFilter: (date: Date|null) => boolean;
  @Input() beforePrevNext: (arg?: any) => Observable<any>;
  @Input() customHeader: ComponentType<any>;

  @Output() monthSelected: EventEmitter<Date> = new EventEmitter<Date>();
}
