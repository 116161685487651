<div mat-dialog-title class="dialog-title">
  <h2 class="title"> {{ 'privacy' | i18n: pageKey }}</h2>
</div>
<div class="privacy-main">
  <section>
    <h2>
      {{ 'data_protection_information_title' | i18n: pageKey }}
    </h2>
    {{ 'data_protection_information1' | i18n: pageKey }}<strong>PwC</strong>{{ 'data_protection_information2' | i18n: pageKey }}
    <a href="mailto: DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</a>
    {{ 'data_protection_information3' | i18n: pageKey }}
  </section>
  <section>
    <strong>
        {{ 'controller_title' | i18n: pageKey }}
    </strong>
    <p>{{ 'controller' | i18n: pageKey }}</p>
  </section>
  <section>
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft<br>
      Friedrich-Ebert-Anlage 35-37<br>
      60327 Frankfurt am Main<br>
      Email: <a href="mailto: DE_Kontakt@pwc.com">DE_Kontakt@pwc.com</a><br>
      Telefonzentrale: +49 69 9585-0<br>
      Fax: +49 69 9585-1000
  </section>
  <section>
    <h2>
        {{ 'data_protection_officer_title' | i18n: pageKey }}
    </h2>
    {{ 'data_protection_officer' | i18n: pageKey }}
  </section>
  <section>
    {{ 'email_protection' | i18n: pageKey }}<a href="mailto: DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</a><br>
    {{ 'phone_protection' | i18n: pageKey }}+49 69 9585-0<br>
  </section>
  <section>
    <strong>
      {{ 'adress_information' | i18n: pageKey }}
    </strong>
</section>
  <section>
    PricewaterhouseCoopers GmbH WPG<br>
    Dr. Tobias Gräber, {{ 'officer_protection' | i18n: pageKey }}<br>
    Friedrich-Ebert-Anlage 35-37<br>
    60327 Frankfurt am Main
  </section>
  <section>
    <h2>
      {{ 'subject_rights_title' | i18n: pageKey }}
    </h2>
    {{ 'rights_information' | i18n: pageKey }}
  </section>
  <section>
    <strong>
     {{ 'right_of_access_title' | i18n: pageKey }}
    </strong>
      {{ 'right_of_access' | i18n: pageKey }}
      {{ 'right_of_access_information' | i18n: pageKey }}
</section>

  <section>
    <strong>
      {{ 'right_to_rectification_title' | i18n: pageKey }}
    </strong>
    {{ 'right_to_rectification' | i18n: pageKey }}
  </section>

  <section>
    <strong>
      {{ 'right_to_erasure_title' | i18n: pageKey }}
    </strong>
    {{ 'right_to_erasure' | i18n: pageKey }}
  <p></p>
    {{ 'right_to_erasure_information1' | i18n: pageKey }}
  <p></p>
    <ul>
      <li>{{ 'right_to_erasure_information2' | i18n: pageKey }}</li>
      <li>{{ 'right_to_erasure_information3' | i18n: pageKey }}</li>
    </ul>
  </section>
  <section>
    <strong>
      {{ 'right_to_restrict_processing_title' | i18n: pageKey }}
    </strong>
    {{ 'right_to_restrict_processing' | i18n: pageKey }}
  </section>
  <section>
    <strong>
      {{ 'right_to_data_portability_title' | i18n: pageKey }}
    </strong>
    {{ 'right_to_data_portability' | i18n: pageKey }}
    <p></p>
      <ul>
        <li>{{ 'right_to_data_portability_information1' | i18n: pageKey }}</li>
        <li>{{ 'right_to_data_portability_information2' | i18n: pageKey }}</li>
      </ul>
  </section>
  <section>
      <h2>
      {{ 'right_to_object_to_processing_title' | i18n: pageKey }}
      {{ 'right_to_object_to_processing' | i18n: pageKey }}
      </h2>
    {{ 'right_to_object_to_processing_information' | i18n: pageKey }}
    </section>
  <section>
    <strong>
      {{ 'email_processing' | i18n: pageKey }}
    </strong>
    <a href="mailto: DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</a>
   </section>
  <section>
    <strong>
      {{ 'mail_processing' | i18n: pageKey }}
    </strong>
    <p></p>
    PricewaterhouseCoopers GmbH WPG<br>
    {{ 'officer_processing' | i18n: pageKey }}<br>
    Friedrich-Ebert-Anlage 35-37<br>
    60327 Frankfurt am Main
  </section>
  <section>
      <h2>
        {{ 'lodge_complaint_title' | i18n: pageKey }}
      </h2>
    {{ 'lodge_complaint' | i18n: pageKey }}
  </section>
  <section>
      <h2>
        {{ 'personal_data_processing_title' | i18n: pageKey }}
      </h2>
    <p>
      {{ 'personal_data_processing1' | i18n: pageKey }}
    </p>
    <p>
    {{ 'personal_data_processing2' | i18n: pageKey }}
    </p>
    <p>
      {{ 'personal_data_processing3' | i18n: pageKey }}
    </p>
  </section>
  <section>
    <h2>
        {{ 'access_website_processing_title' | i18n: pageKey }}
    </h2>
    <section>
        <h3>
          {{ 'access_website_processing_subtitle1' | i18n: pageKey }}
        </h3>
      <p>
        {{ 'access_website_processing1' | i18n: pageKey }}
      </p>
      <ul>
        <li>
          {{ 'ip_adress' | i18n: pageKey }}
        </li>
        <li>
          {{ 'date_time' | i18n: pageKey }}
        </li>
        <li>
          {{ 'timezone' | i18n: pageKey }}
        </li>
        <li>
          {{ 'content' | i18n: pageKey }}
        </li>
        <li>
          {{ 'status_code' | i18n: pageKey }}
        </li>
        <li>
          {{ 'data_volume' | i18n: pageKey }}
        </li>
        <li>
          {{ 'request' | i18n: pageKey }}
        </li>
        <li>
          {{ 'browser' | i18n: pageKey }}
        </li>
        <li>
          {{ 'os' | i18n: pageKey }}
        </li>
        <li>
          {{ 'language' | i18n: pageKey }}
        </li>
      </ul>
      <p>
        {{ 'access_website_processing2' | i18n: pageKey }}
      </p>
      <p>
        {{ 'access_website_processing3' | i18n: pageKey }}
      </p>
    </section>
    <section>
        <h3>
          {{ 'access_website_processing_subtitle2' | i18n: pageKey }}
        </h3>
      {{ 'access_website_processing4' | i18n: pageKey }}<br>
      {{ 'access_website_processing5' | i18n: pageKey }}
    </section>
  </section>
  <section>
      <h2>
        {{ 'getting_in_contact_title' | i18n: pageKey }}
      </h2>
    <p>
      {{ 'getting_in_contact1' | i18n: pageKey }}
    </p>
    <p>
      {{ 'getting_in_contact2' | i18n: pageKey }}
    </p>
    <p>
      {{ 'getting_in_contact3' | i18n: pageKey }}
    </p>
    <p>
      {{ 'getting_in_contact4' | i18n: pageKey }}
    </p>
      <h3>
        {{ 'getting_in_subtitle' | i18n: pageKey }}
      </h3>
    <p>
      {{ 'getting_in_contact5' | i18n: pageKey }}
    </p>
    <p>
      {{ 'getting_in_contact6' | i18n: pageKey }}
    </p>
    <p>
      {{ 'getting_in_contact7' | i18n: pageKey }}
    </p>
  </section>
</div>
