import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AlertService } from '@services/common/alert.service';

@Component({
  selector: 'upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {
  pageName = 'Error_messages';
  fileControl: UntypedFormControl;
  private readonly maxFilesCount = 10000;
  private readonly maxFilesSize = 1e8;

  @Input() multiple: boolean = true;
  @Input() color: 'primary' | 'accent' | 'warn' = 'warn';
  @Input() placeholder: string;
  @Input() type: 'button' | 'input' = 'button';
  @Input() fileType: 'xlsx' | 'zip' = 'xlsx';

  @Output() filesSelected: EventEmitter<any> = new EventEmitter();
  @Output() filesRemoved: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private notification: AlertService,
    private translate: TranslateService,
    protected fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.fileControl = this.fb.control('');
  }

  onChange(event: any) {
    const files = event?.target?.files;

    if (files!=null && files.length > this.maxFilesCount) {
      event.target.value = '';
      return this.notification.error(
        this.translate.instant(`${this.pageName}.error_message_filesCount`, { value: this.maxFilesCount })
      );
    }

    if ([...files].some((item) => item.size > this.maxFilesSize)) {
      event.target.value = '';
      return this.notification.error(
        this.translate.instant(`${this.pageName}.error_message_fileSize`, { value: this.maxFilesSize / 1e6 })
      );
    }

    if ([...files].some((item) => !this.isExcel(item.name))) {
      event.target.value = '';
      return this.notification.error(this.errorMessageWrongType);
    }

    this.fileControl.setValue((Array.from(event.target.files)[0] as File).name);
    this.filesSelected.emit(Array.from(event.target.files));
    event.target.value = '';
  }

  onClick(e:Event): void {
    e.preventDefault();
    this.fileInput.nativeElement.click();
  }

  removeFile(e: Event): void {
    e.preventDefault();
    this.fileControl.setValue('');
    this.filesRemoved.emit(this.fileControl.value);
  }

  isExcel(fileName:string): boolean {
    const type = fileName.substring(fileName.lastIndexOf('.') + 1);

    return type === this.fileType;
  }

  get errorMessageWrongType(): string {
    return this.translate.instant(`${this.pageName}.error_message_filesType`, { fileType: this.fileType });
  }
}
