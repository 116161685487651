import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pwdStrength'
})
export class PwdStrengthPipe implements PipeTransform {
  transform(value: number): any {
    if (value <= 20) {
      return 'weak';
    }
    if (value <= 80) {
      return 'average';
    }
    if (value === 100) {
      return 'strong';
    }
    return '';
  }
}
