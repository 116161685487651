<div class="upload-button">
  <custom-mat-button *ngIf="type === 'button'" (click)="fileInput.click()" [color]="color" customIcon="upload">
    <ng-content></ng-content>
  </custom-mat-button>
  <div class="file-control" *ngIf="type === 'input'">
    <app-input
      [control]="fileControl"
      [placeholder]="placeholder"
      [readonly]="true"
      class="file-control__input"
    ></app-input>
    <a
      *ngIf="!fileControl.value"
      (click)="onClick($event)"
      href="#"
      mat-mini-fab
      [color]="color"
      class="file-control__button"
    >
      <svg-icon src="/assets/icons/upload.svg" class="custom-button__icon"></svg-icon>
    </a>
    <a
      *ngIf="fileControl.value"
      (click)="removeFile($event)"
      href="#"
      mat-mini-fab
      [color]="color"
      class="file-control__button"
    >
      <mat-icon>delete</mat-icon>
    </a>
  </div>
  <input
    #fileInput
    class="upload-button__input"
    type="file"
    [multiple]="multiple"
    [hidden]="true"
    (change)="onChange($event)"
  />
</div>
