import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiConfigService } from '@frpservices/uiconfig.service';

// ref.: https://pwcplus.de/de/datenschutzerklaerung/

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  translateService: TranslateService;
  language: string;
  pageKey: string = 'Privacy';

  constructor(
    public uiConfigService: UiConfigService
  ) {
    this.translateService = uiConfigService.getTranslateService();
  }


  ngOnInit() {
    this.language = this.uiConfigService.selectedLang;
  }

}
