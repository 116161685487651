import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) { }

  public info(message: string, duration?: number, action?: string) {
    this.show('info', message, duration, action);
  }

  public success(message: string, duration?: number, action?: string) {
    this.show('success', message, duration, action);
  }

  public warning(message: string, duration?: number, action?: string) {
    this.show('warning', message, duration, action);
  }

  public error(message: string, duration: number = 3000, action?: string) {
    this.show('error', message, duration, action);
  }

  private show(type:string, message:string, duration:number = 3000, action:string = '') {
    this.snackBar.open(message, action, {
      duration,
      verticalPosition: 'top',
      panelClass: ['notification', `notification_${type}`]
    });
  }
}
