import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toClass'
})
export class ToClassPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.replace(' ', '-');
  }
}
