import { Injectable, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConfigurationService } from '../../configurations/configuration.service';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import { LocalStoreManager } from '../../common/local-store-manager.service';

@Injectable()
export class AuthService {
  
  private _isUserAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isUserAuthenticated: Observable<boolean> = this._isUserAuthenticatedSubject.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private oauthService: OAuthService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager,
    private httpClient: HttpClient) {
  }

  updateUserAuthenticationStatus() {
    return this.httpClient.get<boolean>(this.configurations.apiIsAuthenticatedUrl, { withCredentials: true }).pipe(tap(isAuthenticated => {
      this._isUserAuthenticatedSubject.next(isAuthenticated);
    }));
  }

  setUserAsNotAuthenticated() {
    this._isUserAuthenticatedSubject.next(false);
  }

  login(clientid:string) {
    this.document.location.href = this.configurations.apiLoginUrl + "/" + clientid;
  }

  logout() {
    this.oauthService.logOut(true);
    this.document.location.href = this.configurations.apiLogoutUrl;
  }

  gotoPage(page: string, preserveParams = true) {

    const navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? 'merge' : '', preserveFragment: preserveParams
    };

    this.router.navigate([page], navigationExtras);
  }

  gotoHomePage() {
    this.router.navigate([this.configurations.homeUrl]);
  }

  refreshLogin():any {
    return {};
    //if (this.oauthService.discoveryDocumentLoaded) {
    //  return from(this.oauthService.refreshToken()).pipe(
    //    map(() => this.processLoginResponse(this.oauthService.getAccessToken(), this.rememberMe)));
    //} else {
    //  this.configureOauthService(this.rememberMe);
    //  return from(this.oauthService.loadDiscoveryDocument(this.discoveryDocUrl)).pipe(mergeMap(() => this.refreshLogin()));
    //}
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get accessTokenExpiryDate(): Date {
    return new Date(this.oauthService.getAccessTokenExpiration());
  }
  
}
