<mat-form-field [appearance]="appearance" [ngClass]="size">
  <mat-select [formControl]="control" [placeholder]="placeholder" [(value)]="selected">
    <mat-select-trigger>
      <mat-icon class="fi fi-{{selected}}"></mat-icon>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option">
      <span class="fi fi-{{option}}"></span>
      {{ option | uppercase}}
    </mat-option>
  </mat-select>
</mat-form-field>
