import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'custom-mat-button',
  templateUrl: './mat-button.component.html',
  styleUrls: ['./mat-button.component.scss']
})
export class MatButtonComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'warn';
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'sm';
  @Input() type: 'button' | 'submit' = 'button';
  @Input() link: string;
  @Input() icon: string;
  @Input() customIcon: string;
  @Input() extraClass: string = '';
  @Input() disabled: boolean;
  @Input() autofocus: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  onClick(e: Event): void {
    if (this.type !== 'submit') {
      e.preventDefault();
      this.link ? this.router.navigate([this.link], { relativeTo: this.route }) : this.clicked.emit();
    } else {
      this.clicked.emit();
    }
  }

  get customUrl(): string {
    return `/assets/icons/${this.customIcon}.svg`;
  }
}
