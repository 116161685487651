import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CertificateSettings, Client, FilterSettings } from '@frp/models/client-and-fund.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { DailyEquityRatioComponent } from '../modules/funds/subs/fund-details-subs/records-data/records-data-subs/daily-equity-ratio/daily-equity-ratio.component';

@Injectable({
  providedIn: 'root'
})
export class UiConfigService extends MatPaginatorIntl {

  private filterSettingsObserver = new BehaviorSubject<FilterSettings>(new FilterSettings());
  filterSettings = this.filterSettingsObserver.asObservable();


  clientLogoSafePath: SafeResourceUrl;

  selectedLang: string = navigator.language.toLowerCase().slice(0, 2); // default browser language of the user eg: 'en-US'
  langList: string[] = ['en', 'de'];
  langugeChangeObserver: BehaviorSubject<string>;
  visibleMainHeader: boolean;
  visibleNavHeader: boolean;
  showFundList: boolean;
  showFundTitle: boolean;
  selectedFundId: string;
  showClientLogo: boolean = true;
  showDisclaimer: boolean = true;
  showFunds: boolean = false;
  showFundDirectly: boolean = false;
  client: Client | null;
  yearDownload: string | null;
  seriesName: string | null;
  location: string;

  constructor(private _sanitizer: DomSanitizer, private translate: TranslateService, private router: Router, protected route: ActivatedRoute) {
    super();
    this.langList.indexOf(this.selectedLang) === -1 ? this.selectedLang = "en" : null;
    translate.setDefaultLang(this.selectedLang);
    translate.addLangs(this.langList);
    translate.use(this.selectedLang);
    this.langugeChangeObserver = new BehaviorSubject<string>(this.selectedLang);
    this.translate.onLangChange.subscribe((e: Event) => {
      this.setLabel();
    });
    this.setLabel();
    this.visibleMainHeader = true;
    this.visibleNavHeader = false;
    this.showFundList = false;
    this.showFundTitle = false;
  }

  public setLabel() {
    this.translate.get(['Funds.ITEMS_PER_PAGE_LABEL']).subscribe(label => {
      this.itemsPerPageLabel = label['Funds.ITEMS_PER_PAGE_LABEL'];
      this.changes.next();
    })
  }

  //Todo: use this method to do all design changes for the header. Send the UiConfiguration object as input.
  hide() { this.visibleMainHeader = false; }

  show() { this.visibleNavHeader = true; }


  public designHeader(config: any) {

    if (!config || config == null) return;

    this.clientLogoSafePath = this._sanitizer.bypassSecurityTrustResourceUrl(config.logoBase64);
  }

  public setClientLogo(logobase64String: string) {
    this.clientLogoSafePath = this._sanitizer.bypassSecurityTrustResourceUrl(logobase64String);
  }

  public getTransLanguages(): string[] {
    this.langList = [...this.translate.getLangs()];
    return this.langList;
  }

  public setLanguage(lang: string) {
    this.selectedLang = lang;
    this.translate.use(this.selectedLang);
    this.emitLangugeChange(lang);
  }

  public getTranslateService() {
    return this.translate;
  }

  private emitLangugeChange(lang: string): void {
    this.langugeChangeObserver.next(lang);
  }

  setFilterSettings(settings: FilterSettings) {
    this.filterSettingsObserver.next(settings);
  }

  resetFilterSettings() {
    this.filterSettingsObserver.next(new FilterSettings()); 
  }

}
