import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiConfigService } from '@frpservices/uiconfig.service';

@Component({
  selector: 'opensource',
  templateUrl: './opensource.component.html',
  styleUrls: ['./opensource.component.scss']
})
export class OpensourceComponent implements OnInit {

    translateService: TranslateService;
    language: string;
    pageKey: string = 'Opensource';

  constructor(
    public uiConfigService: UiConfigService
  ) {
    this.translateService = uiConfigService.getTranslateService();
  }


  ngOnInit() {
    this.language = this.uiConfigService.selectedLang;
  }

}
