import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { OrderedPair } from '@models/common/basics.model';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit {
  otherValue: string = '';

  @Input() formArray: UntypedFormArray;
  @Input() options: Array<OrderedPair>;

  constructor() {}

  ngOnInit() {}

  isManualEnter(index: number): boolean {
    return this.options[index].key === 'other';
  }

  getControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }
}
