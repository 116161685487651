import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformDateFormat'
})
export class TransformDateFormatPipe implements PipeTransform {

  transform(dateString: string, language: string): string {

    if (!dateString) {

      return "";

    }

    if (language == 'en') {

      return dateString;

    }

    if (language == 'de') {      

      let dateSections = dateString.split("/");

      // dd/mm

      if (dateSections.length == 2) {

        let date = dateSections[0];

        let month = dateSections[1];

        if (date.length == 2 && month.length == 2) {

          return date + "." + month;

        }

      }

      // dd/mm/yyyy

      if (dateSections.length == 3) {

        let date = dateSections[0];

        let month = dateSections[1];

        let year = dateSections[2];

        if (date.length == 2 && month.length == 2 && year.length == 4) {

          return date + "." + month + "." + year;

        }

      }

    }

    return dateString;

  }

}
