<div class="confirm-dialog__content">
  <div class="title" mat-dialog-title>
    <h5 [innerHTML]="title"></h5>
    <p *ngIf="data['extraTitle']" [innerHTML]="data['extraTitle']"></p>
  </div>
  <mat-dialog-actions>
    <custom-mat-button fxFlex="1 1 50%" size="md" (clicked)="close(true)">Confirm</custom-mat-button>
    <custom-mat-button fxFlex="1 1 50%" size="md" (clicked)="close(false)" color="warn">Cancel</custom-mat-button>
  </mat-dialog-actions>
</div>
