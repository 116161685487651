import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(delta: number): string {
    let seconds = delta / 1000;

    if (seconds <= 1) {
      return `00min 01sec`;
    }

    let minutes = Math.floor(seconds / 60);
    seconds = minutes ? Math.floor(((seconds - minutes * 60) * 100) / 60) : seconds;
    minutes = seconds >= 60 ? minutes + Math.floor(seconds / 60) : minutes;
    seconds = seconds >= 60 ? seconds % 60 : Math.floor(seconds);

    return `${minutes.toString().length === 1 ? '0' + minutes : minutes}min 
            ${('0' + seconds).slice(-2)}sec`;
  }
}
