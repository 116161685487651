import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() fileLink: string;
  @Input() label: string;
  @Input() mode: 'row' | 'column' = 'column';
  @Input() iconName: string;
  @Input() fileName: string;
  @Input() isSmall: boolean;

  @Output() fileSelect = new EventEmitter<File>();

  @ViewChild('fileInput', { static: true }) input: ElementRef;

  constructor() {}

  public reset(): void {
    const file = this.input.nativeElement;

    file.value = file.defaultValue;
    this.fileName = '';
  }

  get isRow(): boolean {
    return this.mode === 'row';
  }

  ngOnInit(): void {}

  onChange(): void {
    const file = this.input.nativeElement.files[0];
    if (file) {
      this.fileName = file.name;
      this.fileSelect.emit(file);
    }
  }

  get uploadedFileName(): string {
    if (typeof this.fileLink === 'string') {
      const arr = this.fileLink.split('/');
      return arr.splice(arr.length - 1, 1)[0];
    } else {
      return this.fileName;
    }
  }
}
