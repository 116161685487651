import { Client } from "../../../frp/models/client-and-fund.model";

export class ApplicationVersion {
  api: string = '';
  ui: string = '';
}

export class OrderedPair {
  key: string;
  value: string;
}

export class OrderedTriple {
  key: string;
  value1: string;
  value2: string;
}

export interface NameRoute {
  name: string;
  route: string;
}

export enum DisplayMode {
  VIEW,
  EDIT,
  COMPLETE,
  FEEDBACK
}

export interface CollectionQueryParams {
  page?: number;
  perPage?: number;
  pageSize?: number;
  query?: string;
  order?: string;
  entityId?: number;
  subjectid?: string;
  clientid?: string;
  country?: string;
  section?: string;
}

export class PagedList<T> {
  data: Array<T>;
  totalCount: number;
  pageSize: number;
}

export class GenericResult {
  isSuccess: boolean;
  message: string;
  statusCode: string;

  //constructor(isSuccess: boolean, message: string) {
  //  this.isSuccess = isSuccess;
  //  this.message = message;
  //}
}

export interface DialogData {
  client: Client;
  attachmentTitle: string;
  attachmentLink: string;
  attachmentEnabled: boolean;
  disclaimerText: string;
  language: string;
  accepted: boolean;
  checked: boolean;
  pageKey: string;
}
