import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut } from '@services/common/animations';

@Component({
  selector: 'app-static-notification',
  templateUrl: './static-notification.component.html',
  styleUrls: ['./static-notification.component.scss'],
  animations: [fadeInOut]
})
export class StaticNotificationComponent {
  @Input() type: 'error' | 'warn' | 'info' | 'success' = 'info';
  @Input() show: boolean;
  @Input() allowClose: boolean;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  icons = {
    error: 'error',
    warn: 'warning',
    info: 'notifications_active',
    success: 'notifications'
  };

  get typeClass(): string {
    return `notification_${this.type}`;
  }

  onClose(): void {
    this.closed.emit();
  }
}
