import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule, routes } from '@app/app.routes';
import { ErrorInterceptor } from './services/infrastructure/error-handler.interceptor';
import { SharedModule } from './components/basics/shared.module';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { appInitializerFactory, createTranslateLoader } from './app.initializer';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AuthService } from './services/infrastructure/authentication/auth.service';
import { AuthGuard } from './services/infrastructure/authentication/auth-guard.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

/** components **/
import { AppComponent } from './components/app.component';
//import { HomeComponent } from './components/home/home.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HeaderComponent } from '../frp/modules/header/header.component';
import { FooterComponent } from '../frp/modules/footer/footer.component';
import { ImprintComponent } from '@frp/modules/imprint/imprint.component';
import { PrivacyComponent } from '@frp/modules/privacy/privacy.component';
import { OpensourceComponent } from '@frp/modules/opensource/opensource.component';



// cookies addition, instead of localhost it can be different domain //

//const cookieConfig: NgcCookieConsentConfig = {
//  cookie: {
//  domain: 'localhost'
//  },
//  position: 'bottom',
//  palette: {
//    popup: {
//      background: '#7d7d7d'
//    },
//    button: {
//      background: '#7d7d7d'
//    }
//  },
//  layout: 'without-deny-button',
//  layouts: {
//    "without-deny-button": '{{buttons}}'
//  },
//  elements:{
//    buttons: `
//    <span id="cookieconsent:desc" class="cc-message">{{message}} {{details}}
//   <a aria-label="learn more about cookies" tabindex="1" class="cc-link" href="{{href}}" target="_blank" rel="noopener">{{link}}</a>
//    </span>
//   <div class="cc-compliance cc-highlight">
//     <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny" ></button>
//     <button aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow" >Allow cookies</button>
//    </span>
//   </div>
//    `,
//  },
//  theme: 'classic',
//  type: 'opt-out',
//  content: {
//    "message": "This website uses cookies to ensure you get the best experience on our website.",
//    "dismiss": "Got it!",
//    "allow": "Allow cookies",
//  /*  "deny": "Refuse cookies",*/
//    "link": "Learn more",
//    "href": "/privacy",
//    "policy": "Cookie Policy",
//    "details": "Details"
//  }

//};


@NgModule({
  declarations: [
    AppComponent,
    //HomeComponent,
    LogoutComponent,
    HeaderComponent,
    FooterComponent,
    ImprintComponent,
    PrivacyComponent,
    OpensourceComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    //RouterModule.forRoot(routes, { enableTracing: false }),
    OAuthModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
 /*   NgcCookieConsentModule.forRoot(cookieConfig),*/
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [TranslateService, Injector]
    },
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
