import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '@services/common/animations';
import { TranslateService } from '@ngx-translate/core';
import { UiConfigService } from '../../../frp/services/uiconfig.service';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    animations: [fadeInOut]
})
export class NotFoundComponent {
  pageKey: string = 'PageNotFound';
  constructor(
    private translateService: TranslateService,
    private uiConfigService: UiConfigService
  ) { }

  someMethod(): void {
    const translation = this.translateService.instant('notFound.404');
    console.log(translation);
  }
}
