
<body class="background">
  <div class="page-header logged-out-container">
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="a-pwc-logo-grid a-lg logo"></div>

      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <p>Thank you for using our Chatbot Platform!</p>
      </div>
      <div class="col-sm-12">
        You have been logged out.
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <h2></h2>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <button class="a-btn a-btn-primary a-btn-lg pink" [routerLink]="'/'">Login again</button>
      </div>
    </div>
   </div>
</body>






<!--<div class="container about-page">
  <header class="pageHeader">
    <h3><i class="fa fa-info-circle fa-lg page-caption" aria-hidden="true"></i> You have been logged out</h3>
  </header>

  <button [routerLink]="'/'"> Back to Main Page</button>
</div>-->
