import { Component, ElementRef, ViewChild, OnInit, OnDestroy  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
/*import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';*/
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  showHead: boolean = false;

  @ViewChild('appContainer', { static: true }) appContainer: ElementRef;

  constructor(private router: Router, private translateService: TranslateService) {
  }

  /*  here are the cookies popup functions, change if it is needed*/

  ngOnInit() {

    //this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //  () => {
    //    //  use this.ccService.getConfig() to do stuff
    // /*   console.log('popupOpen');*/
    //  });

    //this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //  () => {
    //  /*  console.log('popuClose');*/
    //  });
 
    //this.initializeSubscription = this.ccService.initialize$.subscribe(
    //  (event: NgcInitializeEvent) => {
    //    console.log(`initialize: ${JSON.stringify(event)}`);
    //  });

    //this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
    //    if (event.status === 'allow') {
    //  this.ccService.close(false); // Hide revoke button after accepting cookies
    //}
    //});

    ////this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    ////  () => {
    ////    console.log(`revokeChoice`);
    ////  });

    ////this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    ////  (event: NgcNoCookieLawEvent) => {
    ////    console.log(`noCookieLaw: ${JSON.stringify(event)}`);
    ////  });

  // this.translateService.addLangs(['en', 'de']);
  //  this.translateService.setDefaultLang('en');

  //  this.translateService.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy', 'cookie.details'])
  //    .subscribe(data => {

  //      this.ccService.getConfig().content = this.ccService.getConfig().content || {};
  //      //console.log(data)
  //      //console.log(this.ccService.getConfig().content )
  //      // Override default messages with the translated ones
  //      this.ccService.getConfig().content!.header = data['cookie.header'];
  //      this.ccService.getConfig().content!.message = data['cookie.message'];
  //      this.ccService.getConfig().content!.dismiss = data['cookie.dismiss'];
  //      this.ccService.getConfig().content!.allow = data['cookie.allow'];
  //      this.ccService.getConfig().content!.deny = data['cookie.deny'];
  //      this.ccService.getConfig().content!.link = data['cookie.link'];
  //      this.ccService.getConfig().content!.policy = data['cookie.policy'];
  //      this.ccService.getConfig().content!.details = data['cookie.details'];

  //      this.ccService.destroy();//remove previous cookie bar (with default messages)
  //      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
  //    });
  //}

  //ngOnDestroy() {
  //  // unsubscribe to cookieconsent observables to prevent memory leaks
  //  this.popupOpenSubscription.unsubscribe();
  //  this.popupCloseSubscription.unsubscribe();
  //  this.initializeSubscription.unsubscribe();
  //  this.statusChangeSubscription.unsubscribe();
  //  this.revokeChoiceSubscription.unsubscribe();
  //  this.noCookieLawSubscription.unsubscribe();


  }

}
