
import { animate, query, state, style, transition, trigger } from '@angular/animations';



export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate('0.4s ease-in', style({ opacity: 1 }))]),
    transition(':leave', [animate('0.4s 10ms ease-out', style({ opacity: 0 }))])
]);



export function flyInOut(duration: number = 0.2) {
    return trigger('flyInOut', [
        state('in', style({ opacity: 1, transform: 'translateX(0)' })),
        transition('void => *', [style({ opacity: 0, transform: 'translateX(-100%)' }), animate(`${duration}s ease-in`)]),
        transition('* => void', [animate(`${duration}s 10ms ease-out`, style({ opacity: 0, transform: 'translateX(100%)' }))])
    ]);
}

export const fadeInOutSlow = trigger('fadeInOutSlow', [
  transition(':enter', [
    // :enter is alias to 'void => *'
    style({ opacity: 0 }),
    animate(1000, style({ opacity: 0 })),
    animate(1500, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    // :leave is alias to '* => void'
    animate(500, style({ opacity: 0 }))
  ])
]);

export const routeFade = trigger('routeFade', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0, position: 'absolute', left: 0, right: 0, top: 0 })], { optional: true }),
    query(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))], { optional: true }),
    query(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))], { optional: true })
  ])
]);
