import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { ConfigurationService } from '../../configurations/configuration.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthGuard  {
  constructor(@Inject(DOCUMENT) private document: Document,
    private authService: AuthService, private router: Router,
    private configurationService: ConfigurationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {

    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {

    if (this.authService.isUserAuthenticated) {
      return true;
    }

    //this.authService.loginRedirectUrl = url;
    //this.router.navigate(['/login']);
    this.document.location.href = this.configurationService.apiLoginUrl;
    return false;
  }
}
