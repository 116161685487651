<div class="search">
  <form (ngSubmit)="onSubmit()">
    <app-input
      [control]="control"
      [label]="label"
      [clearBtn]="clearBtn"
      (clear)="onClear()"
      placeholder="Enter search text here"
      class="search__control"
    ></app-input>
    <button class="search__button" mat-icon-button type="submit">
      <mat-icon class="search__icon" aria-label="Search">search</mat-icon>
    </button>
  </form>
</div>
