import { Component, Input } from '@angular/core';

@Component({
  selector: 'empty-view',
  templateUrl: './empty-view.component.html',
  styleUrls: ['./empty-view.component.scss']
})
export class EmptyViewComponent {
  @Input() title: string;
  @Input() link: string;
  @Input() button: string;
  @Input() icon: string;
  @Input() content: string;
  @Input() displayMode: 'row' | 'column' = 'column';
}
