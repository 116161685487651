<button
  [disabled]="disabled"
  [type]="type"
  (click)="onClick($event)"
  class="custom-button"
  mat-flat-button
  [color]="color"
  [ngClass]="[size, extraClass]"
  [attr.cdkFocusInitial]="autofocus"
>
  <mat-icon class="custom-button__icon" *ngIf="icon">{{ icon }}</mat-icon>
  <svg-icon *ngIf="customIcon" [src]="customUrl" class="custom-button__icon custom-button__icon_custom"></svg-icon>
  <ng-content></ng-content>
</button>
