<div class="upload-photo" [class.disabled]="disabled">
  <a
    href="#"
    *ngIf="imageUrl && !disabled"
    (click)="remove($event)"
    mat-icon-button
    color="warn"
    class="upload-photo__remove"
  >
    <mat-icon>delete_forever</mat-icon>
  </a>
  <label class="upload-photo__frame" for="photo">
    <mat-icon *ngIf="!disabled" class="upload-photo__add-icon">add</mat-icon>
    <svg-icon *ngIf="!imageUrl" class="upload-photo__default-avatar" src="/assets/images/user-avatar.svg"></svg-icon>
    <img *ngIf="imageUrl" [src]="imageUrl" alt="avatar" />
  </label>
  <input
    [disabled]="disabled"
    (change)="onSelectFile($event)"
    type="file"
    id="photo"
    accept="image/x-png,image/jpg,image/jpeg"
    hidden
  />
</div>
