
<footer class="footer-main" #footer>
  
  <div class="footer-container">
    <ul class="links">
      <li> <a (click)="goToLink('https://www.pwc.de/en/imprint.html')">{{ 'ImpressumTitle' | i18n: pageKey }}</a></li>
      <li class="right"> <a (click)="goToLink('/privacy')">{{ 'DataTitle' | i18n: pageKey }}</a></li>
      <li class="right"> <a (click)="goToLink('/oss')">{{ 'OpensourceTitle' | i18n: pageKey }}</a></li>
    </ul>
    <p class="en-footer">
      © {{ currentYear }} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity.
      Please see<a class="pwc" href="https://www.pwc.com/structure">www.pwc.com/structure</a> for further details.
    </p>
  </div>
</footer>
